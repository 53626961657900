import cn from "classnames";
import React from "react";
export enum HeaderVariant {
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h4 = "h4",
  h5 = "h5",
  h6 = "h6"
}

interface HeaderProps extends React.HTMLAttributes<HTMLHeadElement> {
  variant: HeaderVariant;
}
const Header: React.FC<React.PropsWithChildren<HeaderProps>> = props => {
  const { variant, children, className, ...rest } = props;
  switch (variant) {
    case HeaderVariant.h1:
      return (
        <h1
          className={cn(
            "text-4xl font-semibold mb-6 md:text-6xl md:font-bold md:mb-8",
            className
          )}
          {...rest}
        >
          {children}
        </h1>
      );
    case HeaderVariant.h2:
      return (
        <h2
          className={cn(
            "text-4xl font-semibold mb-6 md:text-5xl md:font-bold md:mb-8",
            className
          )}
        >
          {children}
        </h2>
      );
    case HeaderVariant.h3:
      return (
        <h3 className={cn("text-4xl font-semibold", className)}>{children}</h3>
      );
    case HeaderVariant.h4:
      return (
        <h4 className={cn("text-4xl font-semibold", className)}>{children}</h4>
      );
    case HeaderVariant.h5:
      return (
        <h5 className={cn("text-2xl font-semibold", className)}>{children}</h5>
      );
    case HeaderVariant.h6:
      return (
        <h6 className={cn("text-2xl font-semibold my-4", className)}>{children}</h6>
      );
    default:
      return (
        <div className={className} {...rest}>
          {children}
        </div>
      );
  }
};

export default Header;
