import React from "react";
import Header, { HeaderVariant } from "../../Header";
import Section from "../../../containers/Section";
import GoalWindow from "./GoalWindow";
import Circles from "./assets/circles.svg";
import Paragraph from "../../Paragraph";
import GradientTitle from "../../GradientTitle";

interface GoalsProps {
  title?: string;
  gradientTitlePosition?: number;
  subTitle?: string;
  description?: string;
  image?: any;
}

const GoalSection: React.FC<GoalsProps> = props => {
  const { title, gradientTitlePosition, subTitle, description, image } = props;

  return (
    <Section className="relative pb-20 lg:py-48 bg-blue-5 lg:px-0 md:pl-12 xl:max-w-full">
      <div className="relative z-10 inset-y-0 left-0 w-full lg:w-1/2 lg:absolute">
        <span className="absolute bg-white top-0 bottom-1/2 -right-12 -left-12 lg:inset-y-0 lg:left-0 lg:w-1/2"></span>
        <Circles className="absolute left-0 right-0 h-full w-full" />
        <span className="absolute w-full top-1/2 bottom-0 bg-blue-5 inset-y-0 right-0 lg:top-0 lg:w-1/2"></span>
        <div className="w-74 mb-120 pt-44 z-20 flex justify-center mx-auto lg:top-1/2 lg:left-1/2 lg:absolute lg:-translate-x-1/2 lg:-translate-y-1/2 lg:pt-0 lg:mb-0 lg:block">
          <GoalWindow />
        </div>
      </div>
      <div className="flex flex-col-reverse mt-20 relative z-0 items-center max-w-8xl mx-auto px-4 md:px-12 lg:flex-row lg:justify-end lg:mt-0">
        <div className="flex justify-end w-full lg:w-6/12 lg:mt-0">
          <div className="flex-col text-center md:text-left">
            <p className="text-2xl text-indigo-1-dark font-semibold mb-6">
              {subTitle}
            </p>
            <GradientTitle
              title={title}
              gradientPosition={gradientTitlePosition}
              variant={HeaderVariant.h2}
              color="purple"
              lineBreak={true}
            />
            <Paragraph>{description}</Paragraph>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default GoalSection;
