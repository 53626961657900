import React from "react";
import Section from "../../../containers/Section";
import Badge from "../../Badge";
import { IconType } from "../../Icon";
import NavigationBarSpacer from "../../NavigationBarSpacer";
import Paragraph from "../../Paragraph";
import { GatsbyImage } from "gatsby-plugin-image";
import GradientTitle from "../../GradientTitle";
import { HeaderVariant } from "../../Header";
import HeroCtas from "../../HeroCtas";

interface HeroSectionProps {
  h1Title?: string;
  title?: string;
  gradientTitlePosition?: number;
  description?: string;
  image?: any;
  hoverImage?: any;
  cta?: any;
  secondaryCta?: any;
}

const HeroSection: React.FC<HeroSectionProps> = props => {
  const {
    title,
    gradientTitlePosition,
    h1Title,
    description,
    image,
    hoverImage,
    cta,
    secondaryCta
  } = props;

  return (
    <>
      <div className="bg-blue-3">
        <NavigationBarSpacer />
        <Section className="relative pt-16 md:pt-20 lg:py-20 xl:max-w-full xl:px-0">
          <div className="flex flex-col justify-between max-w-8xl px-0 mx-auto md:px-0  lg:flex-row xl:px-12 2xl:px-0">
            {/* text section */}
            <div className="w-full text-center md:text-left lg:w-1/2">
              <Badge
                className="mx-auto md:mx-0 mb-12"
                iconType={IconType.walmart}
                title={h1Title}
              />
              <GradientTitle
                title={title}
                gradientPosition={gradientTitlePosition}
                variant={HeaderVariant.h2}
                color="purple"
                lineBreak={false}
                className="md:text-6xl"
              />
              <Paragraph className="text-gray-3-dark mt-8 lg:pr-20">
                {description}
              </Paragraph>
              <HeroCtas cta={cta} secondaryCta={secondaryCta} />
            </div>
          </div>
          <div className="mt-20 relative lg:bottom-0 lg:right-0 lg:absolute lg:w-1/2 lg:max-w-2xl">
            <div className="relative w-3/4 lg:ml-28 ml-auto lg:w-full">
              <GatsbyImage
                className=" -right-12 bottom-0 lg:relative"
                image={image}
                alt=""
              />
              <div className="w-60 md:w-85 absolute -left-28 -bottom-28 z-10">
                <GatsbyImage image={hoverImage} alt="" />
              </div>
            </div>
          </div>
        </Section>
      </div>
    </>
  );
};

export default HeroSection;
