import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Section from "../../../containers/Section";
import Header, { HeaderVariant } from "../../Header";
import Paragraph from "../../Paragraph";

interface GrowthProps {
  title?: string;
  gradientTitlePosition?: number;
  subTitle?: string;
  description?: string;
  image?: any;
}

const OptimizeSection: React.FC<GrowthProps> = props => {
  const { title, subTitle, description, image } = props;

  return (
    <div className="py-20 bg-gray-6">
      <Section className="relative flex">
        <div className="flex flex-col items-end lg:items-center text-center md:text-left lg:flex-row">
          <div className="w-full md:w-3/4 lg:w-1/2">
            <GatsbyImage image={image} alt="" />
          </div>
          <div className="w-full flex-col text-center md:text-left lg:w-1/2 lg:ml-24">
            <p className="text-2xl text-blue-2-dark font-semibold mb-4">
              {subTitle}
            </p>
            <Header
              className="md:text-4xl md:font-semibold"
              variant={HeaderVariant.h2}
            >
              {title}
            </Header>
            <Paragraph>{description}</Paragraph>
          </div>
        </div>
      </Section>
    </div>
  );
};

export default OptimizeSection;
