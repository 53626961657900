import React from "react";
import Header, { HeaderVariant } from "../../Header";
import Section from "../../../containers/Section";
import Paragraph from "../../Paragraph";
import { GatsbyImage } from "gatsby-plugin-image";
import GradientTitle from "../../GradientTitle";
import FeaturesCard from "../../FeaturesCard";

interface AnalyticsProps {
  title?: string;
  gradientTitlePosition?: number;
  description?: string;
  image?: any;
  features?: any;
}

const AnalyticsSection: React.FC<AnalyticsProps> = props => {
  const { title, gradientTitlePosition, description, image, features } = props;

  return (
    <Section className="pt-20 lg:pt-36 pb-6">
      <div className="flex flex-col lg:flex-row items-center">
        <div className="text-center w-full sm:w-3/4 lg:w-1/2 lg:pr-20 lg:text-left">
          <Header
            className="md:text-4xl md:font-semibold flex flex-col"
            variant={HeaderVariant.h2}
          >
            <GradientTitle
              title={title}
              gradientPosition={gradientTitlePosition}
              variant={HeaderVariant.h2}
              color="purple"
              lineBreak={true}
              className="md:text-4xl"
            />
          </Header>
          <Paragraph>{description}</Paragraph>
        </div>
        <div className="w-full lg:w-1/2 mt-14 lg:mt-0">
          <GatsbyImage image={image} alt="" />
        </div>
      </div>
      <FeaturesCard page={"walmart"} backgroundColor={"bg-gray-6"} cartDotted={true} features={features} />
    </Section>
  );
};

export default AnalyticsSection;
