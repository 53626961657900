export enum PAGE {
  AMC = "AMC",
  SELLICS_JOINS_PERPETUA = "Sellics Joins Perpetua",
  PAGE_404 = "404",
  BUSINESS_INTELLIGENCE = "business-intelligence",
  REFERRAL = "referral",
  AFFILIATE = "affiliate",
  PRICING = "pricing",
  AMAZON_SEO_TOOL = "amazon-seo-tool",
  VIDEO_ADS = "video-ads",
  COMPETITOR = "competitor",
  AMAZON_PPC = "amazon-ppc",
  AMAZON_ACCELERATOR_PROGRAM = "amazon-accelerator-program",
}
