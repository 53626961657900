import React from "react";
import cn from "classnames";
import Header from "../Header";

interface GradientTitleProps {
  title: any;
  variant: any;
  color: string;
  className?: string;
  gradientPosition: number;
  lineBreak: boolean;
  // gradient or data-sol
  animation?: boolean;
}

const GradientTitle: React.FC<GradientTitleProps> = props => {
  const {
    title,
    variant,
    color,
    className,
    gradientPosition,
    lineBreak,
    animation = true
  } = props;

  const sharedGradientClasses = "bg-gradient-to-r bg-clip-text";

  const gradientText = {
    orange: "from-burnt-1 to-yellow-1",
    blue: "from-indigo-1 to-blue-1",
    purple: "from-purple-1 to-blue-1",
    green: "from-green-1 to-yellow-1"
  };

  return (
    <Header variant={variant} className={className}>
      {title?.map((text, i) => {
        return gradientPosition - 1 === i ? (
          <span
            key={`${text}-${i}`}
            className={cn(
              sharedGradientClasses,
              color ? gradientText[color] : gradientText["blue"],
              "text-transparent",
              { "animate-gradientRotate": animation },
              className,
              lineBreak && "md:block"
            )}
          >{`${text} `}</span>
        ) : (
          <span
            key={`${text}-${i}`}
            className={cn(sharedGradientClasses, className)}
          >{`${text} `}</span>
        );
      })}
    </Header>
  );
};

export default GradientTitle;
