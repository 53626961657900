import React from "react";
import Section from "../../../containers/Section";
import Brands, { BrandObject } from "../../Brands";
import Hydrant from "../../Brands/BrandSvgs/hydrant.svg";
import DrTobias from "../../Brands/BrandSvgs/drtobias.svg";
import HVMN from "../../Brands/BrandSvgs/hvmn.svg";
import Iandlovandyou from "../../Brands/BrandSvgs/iandloveandyou.svg";
import Nightfood from "../../Brands/BrandSvgs/nightfood.svg";
import OWYN from "../../Brands/BrandSvgs/owyn.svg";
import Whatdoyoumeme from "../../Brands/BrandSvgs/whatdoyoumeme.svg";

const BrandSection = () => {
  // array of brand logos
  const brandsList: Array<BrandObject> = [
    { src: <Hydrant />, filter: true },
    { src: <DrTobias />, filter: true },
    { src: <HVMN />, filter: true },
    { src: <Iandlovandyou />, filter: true },
    { src: <Nightfood />, filter: true },
    { src: <OWYN />, filter: true },
    { src: <Whatdoyoumeme />, filter: true }
  ];
  return (
    <Section>
      <div className="pt-20 xs:pb-6 md:pb-20">
        <Brands brandsList={brandsList} center />
      </div>
    </Section>
  );
};

export default BrandSection;
