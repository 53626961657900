import cn from "classnames";
import React from "react";
import "./FeaturesCard.scss";
import { PAGE } from "../../constants/pages";
import Header, { HeaderVariant } from "../Header";

interface FeaturesCardProps {
  features?: any;
  page?: string;
  cartDotted?: boolean;
  backgroundColor?: string;
  border?: boolean;
  headerVariant?: any;
}

const FeaturesCard: React.FC<FeaturesCardProps> = props => {
  const {
    features,
    page,
    cartDotted = false,
    backgroundColor = "bg-gray-6",
    border = false,
    headerVariant = HeaderVariant.h5
  } = props;

  const customBorder = {
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23333' stroke-width='2' stroke-dasharray='5%2c8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`
  };
  
  return (
    <div
      className={cn(
        "py-12 features-card",
        page && page === PAGE.AMAZON_PPC ? "lg:py-8" : "lg:py-20"
      )}
    >
      <div
        className={cn(
          page === "walmart"
            ? "md:w-1/2 lg:w-full mx-auto grid grid-flow-col grid-cols-1 grid-rows-4 lg:mx-0 lg:grid-rows-1 lg:grid-cols-4 gap-4 relative"
            : page === PAGE.AMAZON_PPC
            ? "flex flex-wrap flex-col md:flex-row justify-center gap-8 content-center"
            : "grid lg:grid-flow-row sm:grid-cols-2 sm:grid-rows-2 lg:grid-cols-4 lg:grid-rows-1 gap-4"
        )}
      >
        {features?.map((card, i) => {
          return (
            <div
              key={i}
              style={border ? customBorder : {}}
              className={cn(
                "py-7 px-8 rounded-md relative lg:pl-12 flex",
                page === "walmart" ? "lg:flex-col" : "flex-col lg:flew-row ",
                page === PAGE.AMAZON_PPC && "w-80 lg:w-96",
                backgroundColor
              )}
            >
              <div className="flex lg:w-full">
                <div className="rounded-full h-18 w-18 shadow-xl flex items-center justify-center bg-white mb-3 z-10">
                  {card?.svg?.url ? (
                    <img src={card?.svg?.url} alt="" />
                  ) : (
                    card?.svg
                  )}
                </div>
              </div>
              <div
                className={cn(
                  "lg:mt-8",
                  page === "walmart" ? "pl-12 lg:pl-0" : ""
                )}
              >
                {card?.title && (
                  <Header variant={headerVariant}>
                    <span className="text-lg font-semibold flex items-center mb-8">
                      {card?.title}
                    </span>
                  </Header>
                )}
                <p className="text-lg font-medium flex items-center lg:w-fulll lg:block">
                  {card?.text}
                </p>
              </div>
            </div>
          );
        })}
        {cartDotted && (
          <div className="features-card__dotted-line absolute full z-0 lg:w-3/4 left-10"></div>
        )}
      </div>
    </div>
  );
};
export default FeaturesCard;
