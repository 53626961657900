import React from "react";
import Button, { ButtonVariant } from "../../../Button";
import Icon, { IconType } from "../../../Icon";
import Toggle from "../assets/toggle.svg";
import Edit from "../assets/edit.svg";
import Cog from "../assets/cog.svg";
import Confetti from "react-confetti";
import { useWindowSize } from "../../../../hooks/useWindowSize";

const GoalWindow = () => {
  const [showConfetti, setShowConfetti] = React.useState(false);

  const launchGoal = () => {
    setTimeout(() => {
      setShowConfetti(true);
    }, 500);
  };

  const size = useWindowSize();

  return (
    <>
      <div className="bg-white rounded-lg p-3 border border-gray-4-light shadow-lg flex flex-col text-center z-20">
        <div className="flex flex-col text-base text-left font-semibold justify-between bg-gray-5 p-3 mb-4 rounded-lg border border-gray-3">
          <div className="w-full flex items-center justify-between">
            <p className="text-gray-3-dark flex items-center">
              Unbranded
              <Icon className="ml-1" iconType={IconType.info} />
            </p>
            <Toggle />
          </div>
          <div className="w-full flex flex-wrap">
            <p className="w-full text-indigo-1 my-2">Suggest Targets</p>
            <div className="flex-col w-1/2 pr-1">
              <p className="text-gray-2">Target ROAS</p>
              <div className="rounded-lg border border-gray-3 mt-2 bg-white py-2 px-3">
                <p className="text-gray-3-dark font-light">12</p>
              </div>
            </div>
            <div className="flex-col w-1/2 pl-1">
              <p className="text-gray-2">Daily Budget</p>
              <div className="rounded-lg border border-gray-3 mt-2 bg-white py-2 px-3">
                <p className="text-gray-3-dark font-light">$200</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap text-base text-left font-semibold bg-gray-5 p-3 rounded-lg border border-gray-3">
          <p className="w-full mb-2 text-gray-3-dark flex items-center">
            Unbranded
            <Icon className="ml-1" iconType={IconType.info} />
          </p>
          <span>6 Keywords</span>
          <Edit className="ml-2 mr-1" />
          <span className="text-indigo-1">Edit</span>
        </div>
        <div className="flex justify-center items-center my-6">
          <Cog />
          <span className="text-base text-indigo-1 font-semibold ml-2">
            Advanced
          </span>
        </div>
        <Button
          variant={ButtonVariant.primary}
          onClick={launchGoal}
          text={"Launch Goal"}
          className="mx-auto px-24 py-4 mb-6 bg-indigo-1"
        />
      </div>
      {showConfetti && (
        <Confetti
          colors={["#FF9900", "#c2730d", "#C2410C", "#FEF08A"]}
          style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0 }}
          width={size.width}
          height={size.height}
          run={showConfetti}
          recycle={false}
          numberOfPieces={1000}
          onConfettiComplete={() => setShowConfetti(false)}
        />
      )}
    </>
  );
};
export default GoalWindow;
