import { graphql } from "gatsby";
import React from "react";
import BottomCta from "../components/BottomCta";
import AnalyticsSection from "../components/WalmartPage/AnalyticsSection";
import GoalSection from "../components/WalmartPage/GoalSection";
import HeroSection from "../components/WalmartPage/HeroSection";
import OptimizeSection from "../components/WalmartPage/OptimizeSection";
import GrowthSection from "../components/WalmartPage/GrowthSection";
import BrandSection from "../components/WalmartPage/BrandsSection";
import SEO from "../components/Seo";
import Layout from "../containers/Layout";
import { useSegment, trackView } from "../utils/analytics";
import { nav } from "../utils/navigation";
import { Name, Type, Location, Category, CTA_ID } from "../utils/analytics/constants";
import { useI18next } from "gatsby-plugin-react-i18next";

interface WalmartPageProps {
  data: any;
}

const Walmart: React.FC<WalmartPageProps> = ({ data }) => {
  const { segment, helpers } = useSegment();

  const pageData = data?.allContentfulWalmartPage?.nodes[0];

  const { language } = useI18next();

  return (
    <Layout
      primaryButtonId={CTA_ID.getStartedNav.id}
      locale={language}
    >
      <SEO
        title={pageData?.seoSettings?.metadataTitle}
        description={pageData?.seoSettings?.metadataDescription}
        robots={pageData?.seoSettings?.robots}
      />
      <HeroSection
        h1Title={pageData?.h1Title}
        title={pageData?.heroMainTitle}
        gradientTitlePosition={pageData?.heroMainTitleGradientPosition}
        description={pageData?.heroDescription?.heroDescription}
        image={pageData?.heroImage?.gatsbyImageData}
        hoverImage={pageData?.heroHoverImage?.gatsbyImageData}
        cta={pageData?.heroCta}
        secondaryCta={pageData?.secondaryHeroCta}
      />
      <BrandSection />
      <GoalSection
        title={pageData?.goalsMainTitle}
        gradientTitlePosition={pageData?.goalsMainTitleGradientPosition}
        subTitle={pageData?.goalsSubTitle}
        description={pageData?.goalsDescription?.goalsDescription}
      />
      <OptimizeSection
        title={pageData?.optimizeMainTitle}
        subTitle={pageData?.optimizeSubTitle}
        description={pageData?.optimizeDescription?.optimizeDescription}
        image={pageData?.optimizeImage?.gatsbyImageData}
      />
      <GrowthSection
        title={pageData?.growthTitle}
        subTitle={pageData?.growthSubTitle}
        description={pageData?.growthDescription?.growthDescription}
        image={pageData?.growthImage?.gatsbyImageData}
      />
      <AnalyticsSection
        title={pageData?.analyticsTitleGradient}
        gradientTitlePosition={pageData?.analyticsTitleGradientPosition}
        description={pageData?.analyticsDescription?.analyticsDescription}
        image={pageData?.analyticsImage?.gatsbyImageData}
        features={pageData?.analyticsFeatures}
      />
      <BottomCta
        primaryButtonId={pageData?.bottomCta?.primaryButtonId}
        secondaryButtonId={CTA_ID.demoBottom.id}
        title={pageData?.bottomCta?.title}
        subtitle={pageData?.bottomCta?.subtitle}
        primaryButtonText={pageData?.bottomCta?.primaryButtonText}
        secondaryButtonText={pageData?.bottomCta?.secondaryButtonText}
        primaryButtonClassName={pageData?.bottomCta?.primaryButtonClassName}
        secondaryButtonClassName={pageData?.bottomCta?.secondaryButtonClassName}
        primaryButtonOnClick={() => {
          helpers
            .delayTrack(Name.Cta, {
              category: Category.ButtonClick,
              type: Type.Button,
              text: pageData?.bottomCta?.primaryButtonText,
              page_url: window?.location.href,
              destination: pageData?.bottomCta?.primaryButtonRoute,
              location: Location.BottomCta
            })
            .then(() => nav(pageData?.bottomCta?.primaryButtonRoute));
        }}
        secondaryButtonOnClick={() => {
          trackView(Name.Cta, {
            category: Category.ButtonClick,
            type: Type.Button,
            text: pageData?.bottomCta?.secondaryButtonText,
            page_url: window?.location.href,
            destination: pageData?.bottomCta?.secondaryButtonRoute,
            location: Location.BottomCta
          });
          nav(pageData?.bottomCta?.secondaryButtonRoute);
        }}
      />
    </Layout>
  );
};

export default Walmart;

export const data = graphql`
  query ($language: String) {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allContentfulWalmartPage(filter: { node_locale: { eq: $language } }) {
      nodes {
        seoSettings {
          metadataTitle
          metadataDescription
          openGraphImage {
            url
          }
          robots
        }
        h1Title
        heroMainTitle
        heroMainTitleGradientPosition
        heroDescription {
          heroDescription
        }
        heroImage {
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
        heroHoverImage {
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
        heroCta {
          text
          route
        }
        secondaryHeroCta {
          text
          route
        }
        goalsMainTitle
        goalsMainTitleGradientPosition
        goalsSubTitle
        goalsDescription {
          goalsDescription
        }
        optimizeMainTitle
        optimizeSubTitle
        optimizeDescription {
          optimizeDescription
        }
        optimizeImage {
          url
          gatsbyImageData
        }
        growthTitle
        growthSubTitle
        growthDescription {
          growthDescription
        }
        growthImage {
          url
          gatsbyImageData
        }
        analyticsTitleGradient
        analyticsTitleGradientPosition
        analyticsDescription {
          analyticsDescription
        }
        analyticsImage {
          url
          gatsbyImageData
        }
        analyticsFeatures {
          text
          svg {
            url
          }
        }
        bottomCta {
          title
          subtitle
          primaryButtonId
          primaryButtonText
          secondaryButtonId
          secondaryButtonText
          primaryButtonRoute
          secondaryButtonRoute
          primaryButtonClassName
          secondaryButtonClassName
        }
      }
    }
  }
`;
