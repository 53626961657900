import cn from "classnames";
import React from "react";
import Icon, { IconType } from "../Icon";
import Pill from "../Pill";
import { PAGE } from "../../constants/pages";

interface BadgeProps {
  title?: string;
  iconType?: IconType;
  label?: Boolean;
  className?: string;
  logo?: any;
  page?: string;
  h1BadgeShadow?: boolean;
  iconColor?: string;
}
const Badge: React.FC<BadgeProps> = props => {
  const { iconType, title, label, className, logo, page, h1BadgeShadow = true, iconColor } = props;
  return (
    <div
      className={cn(
        "rounded-lg flex flex-row items-center max-w-max py-2",
        h1BadgeShadow && "shadow-xl bg-white px-3",
        className
      )}
    >
      {page && page === "benchmarker" ? (
        logo
      ) : (
        <div className="flex items-center">
          {iconType && <Icon className={iconColor} iconType={iconType} />}
          <h1
            className={cn(
              "font-semibold text-base",
              iconType && "ml-3",
              page === PAGE.AFFILIATE || page === PAGE.COMPETITOR ? "text-indigo-3-dark" : "text-gray-3-dark"
            )}
          >
            {title}
          </h1>
          {label && <Pill text={"Beta"} />}
        </div>
      )}
    </div>
  );
};

export default Badge;
