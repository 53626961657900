import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Section from "../../../containers/Section";
import Header, { HeaderVariant } from "../../Header";
import Paragraph from "../../Paragraph";

interface OptimizeProps {
  title?: string;
  subTitle?: string;
  description?: string;
  image?: any;
}

const OptimizeSection: React.FC<OptimizeProps> = props => {
  const { title, subTitle, description, image } = props;

  return (
    <div className="bg-indigo-1 text-white">
      <Section className="relative">
        <div className="flex flex-col lg:flex-row items-center text-center md:text-left">
          <div className="w-full pt-20 pb-12 lg:pb-36 lg:py-48 lg:w-1/2 lg:pr-24">
            <p className="text-2xl font-semibold mb-6 text-blue-2">
              {subTitle}
            </p>
            <Header
              className="w-full sm:w-3/4 lg:w-full md:text-4xl md:font-semibold"
              variant={HeaderVariant.h2}
            >
              {title}
            </Header>
            <Paragraph>{description}</Paragraph>
          </div>
          <div className="w-full lg:w-1/2 lg:absolute bottom-0 right-0">
            <GatsbyImage image={image} alt="" />
          </div>
        </div>
      </Section>
    </div>
  );
};

export default OptimizeSection;
