import cn from "classnames";
import React from "react";
import Section from "../../containers/Section";
import Button, { ButtonSize, ButtonVariant } from "../Button";
import { CTA_ID } from "../../utils/analytics/constants";

export enum BottomCTAVariant {
  primary = "primary",
  secondary = "secondary"
}
interface ButtonCTAProps {
  title: string;
  subtitle?: string | React.ReactElement;
  variant?: string;
  primaryButtonText?: string;
  primaryButtonId?: string;
  secondaryButtonText?: string;
  secondaryButtonId?: string;
  primaryButtonClassName?: string;
  secondaryButtonClassName?: string;
  primaryButtonOnClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  secondaryButtonOnClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
}

const BottomCTA: React.FC<ButtonCTAProps> = props => {
  const {
    title,
    subtitle,
    variant = BottomCTAVariant.primary,
    primaryButtonText,
    secondaryButtonText,
    primaryButtonId,
    secondaryButtonId,
    primaryButtonClassName,
    secondaryButtonClassName,
    primaryButtonOnClick,
    secondaryButtonOnClick,
    className
  } = props;
  return (
    <Section>
      <div className={`flex-col items-center justify-center bg-gradient-to-b from-indigo-1 to-indigo-1-dark shadow rounded-xl text-center py-20 md:py-24 lg:py-32 ${className}`}>
        <div
          className={cn(
            "m-auto max-w-full px-9 md:max-w-xl lg:max-w-5xl md:px-0"
          )}
        >
          <h2 className="text-4xl font-semibold text-white lg:text-5xl lg:font-bold">
            {title}
          </h2>
          <p className="text-xl font-normal text-indigo-2-light pt-3 lg:pt-5 sm:max-w-sm lg:max-w-full mx-auto ">
            {subtitle}
          </p>
          <div className="max-w-full md:max-w-xs lg:max-w-5xl flex flex-col items-center justify-center space-y-4 mt-10 lg:flex-row lg:space-y-0 lg:mt-8 lg:space-x-4 mx-auto">
            {variant === BottomCTAVariant.primary && (
              <>
                <Button
                  id={primaryButtonId || CTA_ID.getStartedBottom.id}
                  text={primaryButtonText || "Get Started"}
                  variant={ButtonVariant.primaryWhite}
                  size={ButtonSize.large}
                  className={cn(primaryButtonClassName, "w-full lg:w-max")}
                  onClick={primaryButtonOnClick}
                />
                {secondaryButtonText && (
                  <Button
                    id={secondaryButtonId || CTA_ID.demoBottom.id}
                    text={secondaryButtonText || "Request a Demo"}
                    variant={ButtonVariant.secondaryWhite}
                    size={ButtonSize.large}
                    className={cn(secondaryButtonClassName, "w-full lg:w-max")}
                    onClick={secondaryButtonOnClick}
                  />
                )}
              </>
            )}
          </div>
          {variant === BottomCTAVariant.secondary && (
            <div className="flex flex-col items-center justify-center space-y-4 mt-10 lg:flex-row lg:space-y-0 lg:mt-8 lg:space-x-4">
              <Button
                id={secondaryButtonId}
                text={secondaryButtonText}
                variant={ButtonVariant.primaryWhite}
                size={ButtonSize.large}
                className={cn(secondaryButtonClassName, "w-full lg:w-max")}
                onClick={secondaryButtonOnClick}
              />
            </div>
          )}
        </div>
      </div>
    </Section>
  );
};

export default BottomCTA;
