import classNames from "classnames";
import React from "react";

export interface BrandObject {
  src: Object;
  filter?: boolean;
  onClick?: () => void;
}

interface BrandProps {
  brandsList: BrandObject[];
  center?: boolean;
  className?: string;
}

const Brands: React.FC<BrandProps> = props => {
  // brandsList is an array of objects with 2 properties - src and filter. if filter is true -> grayscale is applied.
  // eg: brandsList = [{src: <Cartograph/>, filter: false}, {src: <Pmg/>, filter: true}]
  const { brandsList, center, className } = props;
  return (
    <div
      className={
        classNames(
          "grid grid-cols-2 justify-center items-center sm:grid-cols-4 lg:flex md:justify-between "
        ) + className
      }
    >
      {brandsList &&
        brandsList?.map((brand, index) => {
          return (
            <div
              key={index}
              aria-hidden={true}
              className={classNames(
                "flex my-7 mx-auto lg:my-10 justify-center items-center",
                {
                  "w-auto": !center,
                  "cursor-pointer": brand?.onClick,
                  grayscale: brand?.filter && !brand?.onClick,
                  "grayscale-hover": brand?.filter && brand?.onClick
                }
              )}
              onClick={brand?.onClick}
            >
              {brand.src}
            </div>
          );
        })}
    </div>
  );
};

export default Brands;
